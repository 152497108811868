import { ReactNode, useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';

import Icon from 'components/Icon';
import useMediaQuery from 'shared/useMediaQuery';
import styles from './index.module.scss';

type SliderProps = {
  items: Array<ReactNode>;
  position: number;
  height: string;
  pageSize: number;
  loadNext?: () => void;
};
const Slider = ({ items, loadNext }: SliderProps) => {
  const [position, setPosition] = useState(0);
  const [scrollLeftCounter, setScrollLeftCounter] = useState(2);
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(600);
  const isTablet = useMediaQuery(900);
  const disableNext = () => {
    const pos = Math.abs(position);
    if (isTablet) {
      return pos >= Math.floor((items.length - 1) / 2);
    }
    return pos >= Math.floor(items.length / 4);
  };

  const slideStyle = {
    '--position': position,
    '--col': items.length,
    backgroundColor: 'transparent',
  };
  const itemStyle = {};

  useEffect(() => {
    if (!isMobile) {
      containerRef.current?.scrollTo(0, 0);
    }
  }, [isMobile]);

  const loadNextvideos = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (loadNext) {
      loadNext();
    }
    setPosition((p) => p - 1);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (event.currentTarget.scrollLeft / scrollLeftCounter >= event.currentTarget.clientWidth) {
      if (loadNext) {
        loadNext();
      }
      setScrollLeftCounter((prev) => prev + 2);
    }
  };

  return (
    <div className={styles.slider}>
      <div className={classNames(styles.buttonGroup, 'hide@sm')}>
        <button
          disabled={position === 0}
          onClick={() => setPosition((p) => p + 1)}
          aria-label="Previous"
        >
          <Icon.ArrowLeft color="" />
        </button>{' '}
        <button disabled={disableNext()} onClick={loadNextvideos} aria-label="Next">
          <Icon.ArrowRight color="" />
        </button>
      </div>
      <div className={styles.container} ref={containerRef} onScroll={handleScroll}>
        <div className={styles.slide} style={slideStyle}>
          {items.map((item, index) => {
            return (
              <div key={`item-${index}`} className={styles.item} style={itemStyle}>
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { Slider };
