import React, { useState } from 'react';
import Grid from 'components/Grid';
import TimeAgo from 'timeago-react';

import { LiveVideo } from 'types';
import * as videoStore from 'store/videos';
import { VideoTile } from 'components/VideoTile';
import Icon from 'components/Icon';

import { Slider } from 'components/Slider';
import { batchUpdates } from 'shared/util';
import { Loadable } from 'store/util';
import styles from './index.module.scss';

const WireSection = () => {
  const [offset, setOffset] = useState(0);
  const [previousVideos, setPreviousVideos] = useState<Array<LiveVideo>>([]);
  const wireVideos = videoStore.getWireVideos({ offset, limit: offset === 0 ? 8 : 4 });

  const newVideos = Loadable.getOrElse([], wireVideos);
  const allVideos = [...previousVideos, ...newVideos];

  const loadNextvideos = () => {
    batchUpdates(() => {
      setPreviousVideos((vs) => {
        return [...vs, ...newVideos];
      });
    });
    setOffset((prevOffset) => (prevOffset === 0 ? prevOffset + 8 : prevOffset + 4));
  };

  return (
    <div className={styles.container}>
      <Grid container justifyContent="space-between">
        <Grid item lg={6}>
          <h2 className={styles.sectionTitle}>
            Recount <span>Wire</span>
          </h2>
        </Grid>
      </Grid>
      <div className={styles.carouselContainer}>
        <Slider
          loadNext={loadNextvideos}
          items={allVideos.map((v) => (
            <div key={v.id} className={styles.tileContainer}>
              <div className={styles.meta}>
                <div className={styles.timeAgo}>
                  <Icon.Clock color="#FF4545" />
                  {v.publishedAt && <TimeAgo datetime={new Date(v.publishedAt * 1000)} />}
                </div>
                <div className={styles.location}>
                  <Icon.Pin color="#7E7E7E" />
                  <span>{v.location}</span>
                </div>
              </div>
              <VideoTile reverse className={styles.tile} video={v} imageWidth={330} />
            </div>
          ))}
          position={0}
          height="500px"
          pageSize={4}
        />
      </div>
    </div>
  );
};

export default WireSection;
