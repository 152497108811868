import { useState, useEffect } from 'react';
import axios from 'axios';
import classNames from 'classnames';

import logging from 'shared/logging';
import { config } from 'shared/config';

import styles from './index.module.scss';

type NewsletterSignupProps = {
  source?: string;
};
const NewsletterSignup = ({ source }: NewsletterSignupProps) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<string | null>(null);
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [successfullySubscribed, setSuccessfullySubscribed] = useState(false);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email.length < 1) {
      setMessage('Please enter an email address.');
      return;
    }

    if (!email.includes('@')) {
      setMessage('Please enter a valid email adress.');
      return;
    }

    const url = `${config.publicApiEnpoint}/newsletters/subscribe`;

    try {
      // Only responses are 201 and 500 (which throws)
      await axios({
        method: 'post',
        url,
        data: {
          data: {
            email,
            source: source || 'website',
          },
        },
      });
      localStorage.setItem('newsletter-modal-sub', 'true');
      setSuccessfullySubscribed(true);
    } catch (err) {
      logging.error('Failure subscribing, error: ', err);
      setMessage('There was a problem subscribing you.');
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setMessage(null);
    setEmail(value);
  };

  useEffect(() => {
    setHasSubscribed(!!localStorage?.getItem('newsletter-modal-sub'));
  }, []);

  return (
    <div className={classNames(styles.container, { hide: hasSubscribed })}>
      <h2>The Recount Daily Newsletter</h2>
      <h3>
        Sign up today. Watch.
        <br />
        Stay informed.
      </h3>
      {successfullySubscribed ? (
        <p>Thank you for subscribing to the Recount Daily newsletter!</p>
      ) : (
        <p>
          Catch up on the most important stories of the day with The Recount Daily Newsletter. Each
          day we bring you a round-up of what&apos;s happening in the corridors of power right now,
          without wasting your time or insulting your intelligence.
        </p>
      )}
      {!successfullySubscribed && (
        <>
          <form onSubmit={onSubmit}>
            <input type="text" value={email} placeholder="Enter your e-mail" onChange={onChange} />
            <button type="submit">Sign Up</button>
          </form>
          {message && <p className={styles.message}>{message}</p>}
        </>
      )}
    </div>
  );
};

export { NewsletterSignup };
